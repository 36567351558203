import React, { useEffect, useState } from 'react';
import { Row, Typography, Tooltip, Col, Divider, Alert } from 'antd';
import PropTypes from 'prop-types';
import { useMixpanel } from 'react-mixpanel-browser';
import { tiempoTranscurrido as obtenerTiempoTranscurrido } from '../../../../constants';
import classes from './HeaderVerOrden1.module.css';
import '../../../atoms/support/Integraciones.module.css';
import ProtectedComponent from '../../../../hoc/ProtectedComponent/index';
import { Permisos } from '../../../../constants/Permisos';
import Local from '../../../atoms/support/Local';
import { formatoFecha } from '../../../utils-components/Date';
import Text from 'antd/lib/typography/Text';
import FinalPrice from '../../../atoms/support/FinalPrice';
import TiempoPedido from '../../../atoms/support/TiempoPedido';
import ReactTimeAgo from 'react-time-ago';
import RevertirPagoButton from '../../../../components/atoms/support/acciones/RevertirPagoButton';
import useSupport from 'hooks/support/useSupport';
import Check from 'assets/svg/Check';
import ORDER_STATES from 'constants/OrderStates/orderStates';

const { Title } = Typography;

const formatDate = (dateObj) => {
    return dateObj
        ? dateObj.toString().replace('.000Z', '').replace('T', ' ')
        : '';
};

const HeaderVerOrden = ({
    orderState,
    ordenOriginal,
    deliveryType,
    orderId,
    localName,
    dateOrder,
    clientName,
    razonSocial,
    ruc,
    phoneNumber,
    address,
    referencia,
    subtotalOrder,
    costoDelivery,
    discountAmount,
    idCliente,
    tipoPago,
    codigoOnline,
    latitudDireccionCliente,
    longitudDireccionCliente,
    addressArr,
    verificarDireccion,
    telefonoLocal,
    tienePermiso,
    costAssumedByFranchise,
    permitirRevertirPagoOnline,
    tipoPagoSoloCupon,
    idEstadoOrden,
    pagoRevertido,
}) => {
    //Hook de mixpanel
    const mixpanel = useMixpanel();
    const idcliente = `ID: ${idCliente}`;
    const datosContribuyente = (
        <div>
            <div>{idcliente}</div>
            <div>Razón Social: {razonSocial}</div>
            <div>RUC: {ruc}</div>
        </div>
    );

    const { revertirPago } = useSupport();

    const revertirPagoButtonHandler = () => revertirPago(ordenOriginal, true);

    const linkGoogleMapsClient = `https://www.google.com/maps/search/?api=1&query=${latitudDireccionCliente},${longitudDireccionCliente}`;
    const [elapsedTimeText, setElapsedTimeText] = useState('Estimando...');
    const [tiempoTexto, setTiempoTexto] = useState('');
    useEffect(() => {
        setTiempoTexto('Transcurrido: ');
        if (dateOrder) {
            let tiempoHoras = '';
            const orderStateId = ordenOriginal?.order_state_id;

            if (orderStateId === 11) {
                setElapsedTimeText(undefined);
            } else if (orderStateId === 6 || orderStateId === 2) {
                setTiempoTexto('Tiempo total: ');
                const orderStateHistories =
                    ordenOriginal?.order_state_histories || [];
                const fechaPedido = ordenOriginal?.confirmed_at;
                const tiempoEstimado = ordenOriginal?.branch?.delivery_delay;

                if (orderStateHistories?.length) {
                    const tiempoFinal = new Date(
                        orderStateHistories[0]?.started_at
                            ?.toString()
                            ?.replace('T', ' '),
                    );
                    const tiempoTotalObj = obtenerTiempoTranscurrido(
                        fechaPedido,
                        tiempoEstimado,
                        tiempoFinal,
                    );
                    const tiempoTotal = tiempoTotalObj?.transcurrido;

                    // Formatear tiempoTotal para tener siempre dos dígitos en la parte de las horas
                    tiempoHoras = tiempoTotal.padStart(5, '0');
                } else {
                    tiempoHoras = '';
                }
                setElapsedTimeText(tiempoHoras);
            } else {
                const timer = setInterval(() => {
                    const fechaPedidoFormateada = formatDate(dateOrder);

                    tiempoHoras = (
                        <ReactTimeAgo
                            date={
                                new Date(
                                    new Date(fechaPedidoFormateada).getTime() +
                                        (180 - new Date().getTimezoneOffset()) *
                                            60 *
                                            1000,
                                )
                            }
                            timeStyle="twitter-now"
                        />
                    );

                    setElapsedTimeText(tiempoHoras);
                }, 1000);

                return () => {
                    clearInterval(timer);
                };
            }
        }
    }, [dateOrder, ordenOriginal]);

    const direccionVerificada = addressArr?.checked;
    const verificarDireccionButtonHandler = () => {
        verificarDireccion();
        //Event log: Cuando se da click en verificar dirección
        mixpanel.track('click_on_verify_address');
    };

    const conditions = {
        orderStateDifferentToCancelled: orderState,
        ifTheOrderIsDifferentFromCancelledAndFinalized:
            orderState !== 'Finalizado',
    };

    const shouldShowAlert =
        elapsedTimeText &&
        elapsedTimeText.props &&
        elapsedTimeText.props.date &&
        idEstadoOrden === ORDER_STATES.Preparando &&
        Date.now() - elapsedTimeText.props.date.getTime() >= 40 * 60 * 1000;

    return (
        <>
            <Row justify="space-between" className={classes.rowInfoModal}>
                <div>
                    ORDEN ID <strong>{orderId}</strong>
                    <div>
                        <Text level={5} className={classes.FechaOrden}>
                            Hecha el {formatoFecha(dateOrder)} hs.
                        </Text>
                    </div>
                </div>
                <div className={classes.DivCondi}>
                    <Title level={5} className={classes.Condition1}>
                        {conditions.orderStateDifferentToCancelled &&
                            conditions.orderStateDifferentToCancelled.toUpperCase()}
                    </Title>
                </div>
                {elapsedTimeText && (
                    <div className={classes.elapsedTime}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '5px' }}>
                                {tiempoTexto}
                            </span>
                            <span style={{ fontWeight: 'bold' }}>
                                {elapsedTimeText}
                            </span>
                        </div>
                        <TiempoPedido
                            tiempoEstimado={
                                ordenOriginal?.branch?.delivery_delay
                            }
                        />
                        {shouldShowAlert && (
                            <Alert
                                message="El Pedido esta llegando a su tiempo estimado de entrega."
                                type="warning"
                                showIcon
                            />
                        )}
                    </div>
                )}
            </Row>
            <Divider />
            <div className={classes.headerDetalle}>
                <Col className={classes.box}>
                    <Local
                        nombreLocal={localName}
                        tipoEntrega={deliveryType}
                        codigoOnline={codigoOnline}
                        telefonoLocal={telefonoLocal}
                        tienePermiso={tienePermiso}
                    />
                    <span className={classes.nombreCliente}>{clientName}</span>
                    <span className={classes.numeroCliente}>{phoneNumber}</span>
                </Col>
                <Col>
                    {razonSocial && (
                        <div className={classes.factura}>
                            <Tooltip
                                placement="topLeft"
                                title={datosContribuyente}>
                                FACTURA
                            </Tooltip>
                        </div>
                    )}
                    {razonSocial && (
                        <div style={{ marginTop: '8px', marginBottom: '10px' }}>
                            <div className={classes.ruc}>RUC: {ruc}</div>
                            <div
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px',
                                }}>
                                <span className={classes.razonSocial}>
                                    {razonSocial}
                                </span>
                            </div>
                        </div>
                    )}
                </Col>
                {deliveryType === 1 && (
                    <div className={classes.boxResumenDireccion}>
                        <ProtectedComponent
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            permiso={Permisos.support.verificarDireccion}>
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                {conditions.ifTheOrderIsDifferentFromCancelledAndFinalized && (
                                    <div className={classes.direccion}>
                                        DIRECCIÓN
                                    </div>
                                )}
                                {conditions.ifTheOrderIsDifferentFromCancelledAndFinalized &&
                                    direccionVerificada && (
                                        <div
                                            className={
                                                classes.direccionVerificada
                                            }>
                                            <Check />
                                            <strong
                                                style={{
                                                    marginLeft: '4px',
                                                }}>
                                                Verificada
                                            </strong>
                                        </div>
                                    )}
                                {conditions.ifTheOrderIsDifferentFromCancelledAndFinalized &&
                                    !direccionVerificada && (
                                        <div
                                            style={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                            }}>
                                            <div>
                                                <div
                                                    size="small"
                                                    onClick={
                                                        verificarDireccionButtonHandler
                                                    }
                                                    className={
                                                        classes.buttonVerificar
                                                    }>
                                                    <span
                                                        style={{
                                                            marginLeft: '15px',
                                                        }}>
                                                        VERIFICAR
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </span>
                        </ProtectedComponent>
                        <div className={classes.address}>{address}</div>
                        <span className={classes.referencia}>{referencia}</span>
                        <span style={{ marginTop: '12px' }}>
                            <a
                                href={linkGoogleMapsClient}
                                target="_blank"
                                rel="noreferrer"
                                className={classes.verMapa}>
                                Ver mapa
                            </a>
                        </span>
                    </div>
                )}
                <div className={classes.boxResumen}>
                    <div className={classes.boxTotal}>
                        <strong>SUBTOTAL</strong>
                        <span className={classes.moneyWithoutBold}>
                            {subtotalOrder}
                        </span>
                    </div>
                    <div className={classes.boxTotal}>
                        <strong>DELIVERY</strong>
                        <span className={classes.moneyWithoutBold}>
                            {costoDelivery}
                        </span>
                    </div>
                    {costAssumedByFranchise && (
                        <div className={classes.boxTotal}>
                            <strong>DESCUENTO</strong>
                            <span className={classes.discountText}>
                                - {discountAmount}
                            </span>
                        </div>
                    )}
                    <div className={classes.boxTotal}>
                        <strong>TOTAL</strong>
                        <div className={classes.NumberPrice}>
                            <FinalPrice ordenOriginal={ordenOriginal} />
                        </div>
                    </div>
                    <div className={classes.payment}>
                        <Local
                            tipoPago={tipoPago}
                            codigoOnline={codigoOnline}
                        />

                        {idEstadoOrden === ORDER_STATES.Cancelados && (
                            <RevertirPagoButton
                                tipoPago={tipoPago}
                                permitirRevertirPagoOnline={
                                    permitirRevertirPagoOnline
                                }
                                revertirPagoButtonHandler={
                                    revertirPagoButtonHandler
                                }
                                tipoPagoSoloCupon={tipoPagoSoloCupon}
                                pagoRevertido={pagoRevertido}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeaderVerOrden;

HeaderVerOrden.propTypes = {
    orderId: PropTypes.any,
    tiempoEstimado: PropTypes.any,
    orderState: PropTypes.any,
    ordenOriginal: PropTypes.object,
    deliveryType: PropTypes.any,
    localName: PropTypes.any,
    dateOrder: PropTypes.any,
    clientName: PropTypes.any,
    razonSocial: PropTypes.any,
    ruc: PropTypes.any,
    phoneNumber: PropTypes.any,
    address: PropTypes.any,
    referencia: PropTypes.any,
    subtotalOrder: PropTypes.any,
    costoDelivery: PropTypes.any,
    finalPrice: PropTypes.any,
    idCliente: PropTypes.any,
    tipoPago: PropTypes.any,
    codigoOnline: PropTypes.any,
    latitudDireccionCliente: PropTypes.any,
    longitudDireccionCliente: PropTypes.any,
    addressArr: PropTypes.any,
    cerrarModalVerOrden: PropTypes.any,
    verificarDireccion: PropTypes.func,
    clientCashAmount: PropTypes.string,
    discountAmount: PropTypes.any,
    telefonoLocal: PropTypes.any,
    permitirRevertirPagoOnline: PropTypes.bool,
    tipoPagoSoloCupon: PropTypes.bool,
    pagoRevertido: PropTypes.bool,
    tienePermiso: PropTypes.bool,
    costAssumedByFranchise: PropTypes.bool,
    idEstadoOrden: PropTypes.number,
};
