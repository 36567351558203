/* eslint-disable camelcase */
export const ambientes = { dev: 'dev', prod: 'prod' };

// Variables de entorno globales
const global = {
    REACT_APP_GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
};

// Variables de entorno para produccion
const prod = {
    ...global,
    REACT_APP_BACKEND_URL_A: process.env.REACT_APP_BACKEND_URL_PROD_A,
    REACT_APP_BACKEND_URL_B: process.env.REACT_APP_BACKEND_URL_PROD_B,
    REACT_APP_BACKEND_URL_C: process.env.REACT_APP_BACKEND_URL_PROD_C,
    REACT_APP_BACKEND_URL_D: process.env.REACT_APP_BACKEND_URL_PROD_D,
    REACT_APP_BACKEND_URL_E: process.env.REACT_APP_BACKEND_URL_PROD_E,
    REACT_APP_BACKEND_URL_F: process.env.REACT_APP_BACKEND_URL_PROD_F,
    REACT_APP_BACKEND_URL_INTEGRATION_JEDI_G:
        process.env.REACT_APP_BACKEND_URL_PROD_INTEGRATION_JEDI_G,
    REACT_APP_BACKEND_URL_INTEGRATION_H:
        process.env.REACT_APP_BACKEND_URL_PROD_INTEGRATION_H,
    REACT_APP_BACKEND_URL_WS_SUPPORT:
        process.env.REACT_APP_BACKEND_URL_PROD_WS_SUPPORT,
    REACT_APP_BACKEND_URL_SV_RECEPTION:
        process.env.REACT_APP_BACKEND_URL_PROD_SV_RECEPTION,
};

// Variables de entorno para desarrollo
const dev = {
    ...global,
    REACT_APP_BACKEND_URL_A: process.env.REACT_APP_BACKEND_URL_DEV_A,
    REACT_APP_BACKEND_URL_B: process.env.REACT_APP_BACKEND_URL_DEV_B,
    REACT_APP_BACKEND_URL_C: process.env.REACT_APP_BACKEND_URL_DEV_C,
    REACT_APP_BACKEND_URL_D: process.env.REACT_APP_BACKEND_URL_DEV_D,
    REACT_APP_BACKEND_URL_E: process.env.REACT_APP_BACKEND_URL_DEV_E,
    REACT_APP_BACKEND_URL_F: process.env.REACT_APP_BACKEND_URL_DEV_F,
    REACT_APP_BACKEND_URL_INTEGRATION_JEDI_G:
        process.env.REACT_APP_BACKEND_URL_DEV_INTEGRATION_JEDI_G,
    REACT_APP_BACKEND_URL_INTEGRATION_H:
        process.env.REACT_APP_BACKEND_URL_DEV_INTEGRATION_H,
    REACT_APP_BACKEND_URL_WS_SUPPORT:
        process.env.REACT_APP_BACKEND_URL_DEV_WS_SUPPORT,
    REACT_APP_BACKEND_URL_SV_RECEPTION:
        process.env.REACT_APP_BACKEND_URL_DEV_SV_RECEPTION,
};

export const urls =
    process.env.REACT_APP_NODE_ENV === ambientes.dev ? dev : prod;

export const localStorageItems = {
    token: 'token',
    permisos: 'permisos',
    id: 'id',
    role: 'role',
    nombre: 'nombre',
    apellido: 'apellido',
    email: 'email',
};

// TODO: Incorporar en la base de datos - Backend
export const document_types = ['CEDULA DE IDENTIDAD', 'R.U.C.'];
export const countries = ['PARAGUAY', 'VENEZUELA'];
export const payment_method = [
    'Transf._SIPAP',
    'Credito_en_Cta.Cte.',
    'Credito_en_Caja_de_Ahorros',
];
export const money = ['GUARANIES', 'DOLARES'];
export const entity_code = [
    'BANCO AMAMBAY SA',
    'BANCO ATLAS SA',
    'BANCO BILBAO VIZCAYA ARGENTARIA PARAGUAY SA',
    'BANCO CONTINENTAL SAECA',
    'BANCO FAMILIAR SAECA',
    'BANCO GNB PARAGUAY SA',
    'BANCO ITAPUA SAECA',
    'BANCO ITAU PARAGUAY SA',
    'BANCO NACIONAL DE FOMENTO',
    'BANCO PARA LA COMERCIALIZACION Y LA PRODUCCION',
    'BANCO REGIONAL SAECA',
    'EL COMERCIO FINANCIERA SAECA',
    'FINANCIERA PARAGUAYO-JAPONESA SAECA',
    'INTERFISA BANCO S.A.E.C.A.',
    'SOLAR SA DE AHORRO Y PRESTAMO PARA LA VIVIENDA',
    'VISION BANCO SAECA',
];

export const tipoPagoOpciones = [
    { key: 0, nombre: 'Todos', valor: '' },
    { key: 1, nombre: 'POS', valor: 'POS' },
    { key: 2, nombre: 'Efectivo', valor: 'CASH' },
    { key: 3, nombre: 'Bancard', valor: 'BANCARD' },
    { key: 4, nombre: 'Descuento', valor: 'DISCOUNT' },
    // {key: 3, nombre: 'Online', valor: 'ONLINE'}, // TODO: Esperar la habilitacion de esta opcion en el backend
];

export const invoice_states = [
    {
        id: 1,
        name: 'pendiente',
    },
    {
        id: 2,
        name: 'facturado',
    },
    {
        id: 3,
        name: 'pagado',
    },
    {
        id: 4,
        name: 'anulado',
    },
];

export const orders_states = [
    { key: 1, name: 'open', label: 'Pendiente' },
    { key: 2, name: 'canceled', label: 'Cancelada' },
    { key: 3, name: 'confirmed', label: 'Confirmada' },
    { key: 4, name: 'in_progress', label: 'Cocina' },
    { key: 5, name: 'delivery', label: 'Delivery' },
    { key: 6, name: 'delivered', label: 'Entregada' },
    { key: 7, name: 'finished', label: 'Finalizada' },
    { key: 8, name: 'deleted', label: 'Eliminada' },
    { key: 9, name: 'pickup', label: 'Retirar' },
    { key: 10, name: 'rejected', label: 'Rechazado' },
    { key: 11, name: 'to_schedule', label: 'Agendando' },
    { key: 12, name: 'schedule', label: 'Agendado' },
];

export const milisegundosAHoras = (duration) => {
    let seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor(duration / (1000 * 60 * 60));

    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds;
};

export const tiempoTranscurrido = (fecha, tiempoEstimado, fechaComparacion) => {
    const fechaPedidoFormateada = fecha
        ? fecha.toString().replace('.000Z', '').replace('T', ' ')
        : '';
    const fechaFin = fechaComparacion
        ? fechaComparacion
        : new Date(
              new Date().getTime() +
                  (new Date().getTimezoneOffset() - 180) * 60 * 1000,
          );
    const difEnMilisegundos = Math.abs(
        fechaFin - new Date(fechaPedidoFormateada.replace(/-/g, '/')),
    );
    const isAtrasado = difEnMilisegundos / 60000 > tiempoEstimado;
    const transcurrido = milisegundosAHoras(difEnMilisegundos);
    return { transcurrido, difEnMilisegundos, isAtrasado };
};

export const cantidadPaginasSupportPendientes = 50;
export const cantidadPaginasSupport = 15;

export const clearLocalStorage = () => {
    localStorage.removeItem(localStorageItems.token);
    localStorage.removeItem(localStorageItems.permisos);
    localStorage.removeItem(localStorageItems.id);
    localStorage.removeItem(localStorageItems.nombre);
    localStorage.removeItem(localStorageItems.apellido);
    localStorage.removeItem(localStorageItems.email);
};
